<template>
  <div v-if="item.customizable_options?.length">
    <div class="cart-line__personalize-heading">
      <IconSecure class="icon icon--secure" />
      <span>{{ $t('Personalization') }}</span>
      <slot name="price" />
    </div>
    <div
      class="cart-line__personalize-data"
    >
      <span
        v-for="(option, index) in item.customizable_options"
        :key="index"
      >
        {{ option.label?.split('|')?.[1] || option.label }}: {{ option.values?.[0]?.label || option.values?.[0]?.value }}
      </span>
    </div>

    <ProductCustomizableOptions
      class="cart-line__personalize-edit"
      :reference="`cart-item-${item.id}`"
      :product="item.product"
      :data="currentData"
      @submit="updateCustomizableOptions"
    />
  </div>
</template>

<script setup lang="ts">
import type { MagentoCartItem } from '~layers/app/types/magento'

export interface Props {
  item: MagentoCartItem
}

const { updateCartItem } = useCart()
const props = defineProps<Props>()

const currentData = computed<Record<string, string>>(() =>
  props.item.customizable_options?.reduce((acc: Record<string, string>, item: any) => {
    acc[item.customizable_option_uid] = item.values[0]?.value || ''
    return acc
  }, {}) || {},
)

const updateCustomizableOptions = async (customizable_options: any) => {
  await updateCartItem({
    cart_item_id: +props.item.id,
    quantity: props.item.quantity,
    customizable_options,
  }, true)
}
</script>
